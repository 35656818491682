import '../sass/frontend.scss';
import Popup from './parts/popup-window';
const dd = document.querySelector('.test');
dd && dd.classList.add('tett8');
const popupInstance = new Popup();
popupInstance.init();
console.log('sdfsdsd');
document.addEventListener('DOMContentLoaded', function () {
    const accordionItems = document.querySelectorAll('.js-accordion-item');
    accordionItems &&
        accordionItems.forEach((item) => {
            const btn = item.querySelector('.js-accordion-btn');
            btn.addEventListener('click', function () {
                accordionItems.forEach((i) => {
                    if (i !== item) {
                        i.classList.remove('active');
                        i.querySelector('.faq-list__content').style.maxHeight = null;
                    }
                });
                const content = item.querySelector('.faq-list__content');
                if (content instanceof HTMLElement) {
                    if (item.classList.contains('active')) {
                        item.classList.remove('active');
                        content.style.maxHeight = null;
                    }
                    else {
                        item.classList.add('active');
                        content.style.maxHeight = `${content.scrollHeight}px`;
                    }
                }
            });
        });
    const mapChange = document.querySelectorAll('.js-map-change');
    mapChange &&
        mapChange.forEach((item) => {
            item.addEventListener('click', (event) => {
                event.preventDefault();
                const currentSelect = document.querySelector('.js-map-change.active');
                const currentMap = document.querySelector('.js-map-image.active');
                if (currentSelect) {
                    currentSelect.classList.remove('active');
                }
                if (currentMap) {
                    currentMap.classList.remove('active');
                }
                const selectMap = event.target.getAttribute('data-map');
                const mapEl = document.querySelector(`.js-map-image[data-map="${selectMap}"]`);
                event.target.classList.add('active');
                if (mapEl) {
                    mapEl.classList.add('active');
                }
                if (window.innerWidth < 991) {
                    event.target.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                        inline: 'nearest',
                    });
                }
            });
        });
    const menuOpen = document.querySelectorAll('.js-hamburger');
    menuOpen &&
        menuOpen.forEach((item) => {
            item.addEventListener('click', (event) => {
                document.body.classList.toggle('open-menu');
            });
        });
});
